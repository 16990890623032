<template>
  <div style="height: 100vh">
    <div
      class="h-100 align-items-center justify-content-center d-none d-md-flex"
    >
      <b-card
        style="border-radius: 20px; border: 1px solid lightgrey"
        class="px-1 m-0"
      >
        <div class="mt-1">
          <b-link :to="{ name: 'Login' }">
            <img :src="this.appLogoImage" img-fluid width="200" height="75"
          /></b-link>
        </div>

        <b-card-title
          title-tag="h2"
          class="font-weight-bolder text-colorBlack mb-50 mt-2 pr-5 mr-5"
        >
          Forgot password?
        </b-card-title>
        <b-card-text class="text-colorGray" style="font-size: 0.8rem">
          Please enter the email or phone number associated with your account,
          <br />
          and we will send you a code to reset your password.
        </b-card-text>

        <validation-observer ref="forgotPasswordFormValidation">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <b-form-group label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  name="email"
                  placeholder="Enter Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="colorBlue"
              class="mt-2"
              block
              @click="validateForm"
              :to="{ name: 'VerificationCode' }"
            >
              Send Verification Code
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>

    <div class="d-block d-md-none h-100">
      <div style="" class="m-0 h-100">
        <b-row style="height: 10%">
          <b-col md="12" style="border-bottom: 2px solid lightgrey">
            <b-row>
              <b-col sm="12" class="p-0">
                <b-navbar toggleable="lg" class="px-2">
                  <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                  <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                      <b-nav-item href="#">Link</b-nav-item>
                    </b-navbar-nav>

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                      <b-nav-item-dropdown text="Lang" right>
                        <b-dropdown-item href="#">EN</b-dropdown-item>
                        <b-dropdown-item href="#">ES</b-dropdown-item>
                        <b-dropdown-item href="#">RU</b-dropdown-item>
                        <b-dropdown-item href="#">FA</b-dropdown-item>
                      </b-nav-item-dropdown>

                      <b-nav-item-dropdown right>
                        <template #button-content>
                          <em>User</em>
                        </template>
                        <b-dropdown-item href="#">Profile</b-dropdown-item>
                        <b-dropdown-item href="#">Sign Out</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </b-navbar-nav>
                  </b-collapse>
                  <b-navbar-brand href="#">
                    <img
                      :src="this.appLogoImage"
                      img-fluid
                      width="100"
                      height="75"
                  /></b-navbar-brand>
                </b-navbar>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="px-2 py-auto" style="height: 90%">
          <div class="mt-5 pt-2">
            <b-link :to="{ name: 'Login' }">
              <img :src="this.appLogoImage" img-fluid width="200" height="75"
            /></b-link>
          </div>

          <b-card-title
            title-tag="h2"
            class="font-weight-bolder text-colorBlack mb-50 mt-2 pr-5 mr-5"
          >
            Forgot password?
          </b-card-title>
          <b-card-text class="text-colorGray" style="font-size: 0.8rem">
            Please enter the email or phone number associated with your account,
            <br />
            and we will send you a code to reset your password.
          </b-card-text>

          <validation-observer ref="forgotPasswordFormValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    name="email"
                    placeholder="Enter Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="colorBlue"
                class="mt-2"
                block
                @click="validateForm"
                :to="{ name: 'VerificationCode' }"
              >
                Send Verification Code
              </b-button>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, util],
  data() {
    return {
      appName: "",
      appFullName: "",
      appFullName: "",
      email: "",
      required,
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appFullName = $themeConfig.app.appFullName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  methods: {
    ...mapActions({ login: "appData/login" }),
    async validateForm() {
      const success = await this.$refs.forgotPasswordFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {},
  },
  computed: {
    ...mapGetters({ hasRole: "appData/hasRole" }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped></style>
